import { GameObjects } from 'phaser';
import Image = Phaser.GameObjects.Image;
import ParticleEmitter = Phaser.GameObjects.Particles.ParticleEmitter;
import { Cell } from './grid.cell';
import { websocket } from './game';
import { BreakUpdate, CellUpdate } from '../shared/models/break.events';
import {
  Point,
  SharedGameGrid,
  SharedGameGridConfig,
} from '../shared/models/shared.game.grid';
import { findTreasureJsonItem, TreasureGridItem } from '../shared/models/treasure';
import { MainScene } from './main.scene';
import Between = Phaser.Math.Between;

export type GridRenderConfig = {
  cellSize: number;
  parentContainer: GameObjects.Container;
  bgContainer: GameObjects.Container;
  treasuresContainer: GameObjects.Container;
};

export class Grid {
  public readonly renderConfig: GridRenderConfig;
  public readonly gameConfig: SharedGameGrid;
  public readonly scene: MainScene;
  public readonly cells: Cell[][];
  private background?: Image;
  public readonly selector: Image;
  public readonly particles: ParticleEmitter;
  public readonly cellCenterOffset: number;
  public readonly treasures: Map<string, TreasureGridItem>;
  public readonly treasuresRender: Map<string, Image>;

  constructor(renderConfig: GridRenderConfig, gameConfig: SharedGameGridConfig) {
    this.gameConfig = new SharedGameGrid(gameConfig);
    this.renderConfig = renderConfig;
    this.scene = <MainScene>renderConfig.parentContainer.scene;
    this.treasures = new Map();
    this.treasuresRender = new Map();
    this.cells = new Array(gameConfig.xSize);
    this.cellCenterOffset = renderConfig.cellSize / 2;
    this.selector = this.scene.add
      .image(0, 0, 'cell-hover')
      .setDisplayOrigin(0, 0)
      .setDisplaySize(this.renderConfig.cellSize, this.renderConfig.cellSize)
      .setVisible(false);
    this.particles = this.scene.add.particles('flares').createEmitter({
      frame: 'blue',
      lifespan: 500,
      speedX: { min: -200, max: 200 },
      speedY: { min: -200, max: 150 },
      angle: 0,
      gravityY: 350,
      scale: { start: 0.35, end: 0 },
      blendMode: 'ADD',
      on: false,
    });
  }

  public xPixelSize() {
    return this.gameConfig.config.xSize * this.renderConfig.cellSize;
  }

  public yPixelSize() {
    return this.gameConfig.config.ySize * this.renderConfig.cellSize;
  }

  public absoluteEndX() {
    // x container offset + (num of cell in a row * size of a cell)
    return this.renderConfig.parentContainer.x + this.xPixelSize();
  }

  public absoluteEndY() {
    // y container offset + (num of cell in a column * size of a cell)
    return this.renderConfig.parentContainer.y + this.yPixelSize();
  }

  public forEachCell(callback: (cell: Cell) => void, filter?: (cell: Cell) => boolean) {
    this.cells.forEach((row) => {
      row.forEach((cell) => {
        if (filter === undefined || filter(cell)) {
          callback(cell);
        }
      });
    });
  }

  private initCells() {
    for (let x = 0; x < this.gameConfig.config.xSize; x++) {
      this.cells[x] = new Array(this.gameConfig.config.ySize);
      for (let y = 0; y < this.gameConfig.config.ySize; y++) {
        this.cells[x][y] = new Cell(this, x, y, this.gameConfig.get({ x, y }));
      }
    }
  }

  private renderCells() {
    this.forEachCell((cell) => {
      cell.render();
    });
  }

  private renderTreasLeftLabel(packet: BreakUpdate) {
    if (packet.treasLeftNum !== undefined) {
      this.scene.setTreasLeft(packet.treasLeftNum);
    }
  }

  private renderNewTreasures(packet: BreakUpdate) {
    packet.treasuresFound.forEach((item) => {
      const jsonItem = findTreasureJsonItem(item.tid);
      if (jsonItem === undefined) {
        console.error(
          "Can't find this treasureID in my own source code, maybe a version mismatch ?",
        );
        return;
      }

      if (!this.treasuresRender.has(item.lid)) {
        const treas = this.scene.add
          .image(
            item.offset.x * this.renderConfig.cellSize,
            item.offset.y * this.renderConfig.cellSize,
            'treasures',
            item.tid,
          )
          .setDisplayOrigin(0, 0)
          .setDisplaySize(
            jsonItem.size.x * this.renderConfig.cellSize,
            jsonItem.size.y * this.renderConfig.cellSize,
          );

        this.renderConfig.treasuresContainer.add(treas);
        this.treasuresRender.set(item.lid, treas);
      }

      if (
        item.cellsLeft.length === 0 &&
        this.treasuresRender.has(item.lid) &&
        (!this.treasures.has(item.lid) ||
          (this.treasures.get(item.lid)?.cellsLeft.length || 0) > 0)
      ) {
        const renderObj = this.treasuresRender.get(item.lid);
        if (renderObj !== undefined) {
          renderObj.setPostPipeline('GlowEffect');
          renderObj.setData(
            'glowTask',
            this.scene.tweens.add({
              targets: renderObj.getPostPipeline('GlowEffect'),
              intensity: 0.012,
              ease: Phaser.Math.Easing.Sine,
              duration: Between(500, 1000),
              repeat: -1,
              yoyo: true,
            }),
          );
        }
      }
      this.treasures.set(item.lid, item);
    });
  }

  private listenEvents() {
    websocket.listen('break-update', (packet: BreakUpdate) => {
      this.renderNewTreasures(packet);
      this.renderTreasLeftLabel(packet);
      packet.affected.forEach((cell: CellUpdate) => {
        this.cells[cell.which.x][cell.which.y].onBreak(cell);
      });
      this.scene.healthBar?.setValue(packet.newHitCount);
    });
  }

  public render() {
    this.background = this.scene.add
      .image(0, 0, 'grid-background')
      .setDisplayOrigin(0, 0)
      .setSize(
        this.gameConfig.config.xSize * this.renderConfig.cellSize,
        this.gameConfig.config.ySize * this.renderConfig.cellSize,
      );
    this.renderConfig.bgContainer.add(this.background);
    this.initCells();
    this.renderCells();
    this.listenEvents();
  }

  public getPixelFromPoint(which: Point) {
    return {
      x: which.x * this.renderConfig.cellSize,
      y: which.y * this.renderConfig.cellSize,
    };
  }
}
