import SimplexNoise from 'simplex-noise';
import { PRNG } from '@utils/util';

export type Point = {
  x: number;
  y: number;
};

export type SharedGameGridConfig = {
  id: string;
  xSize: number;
  ySize: number;
  initialInner?: number[][];
};

export class SharedGameGrid {
  public readonly config: SharedGameGridConfig;
  public readonly randomFn?: PRNG;
  private readonly inner: number[][];
  constructor(config: SharedGameGridConfig, randomFn?: PRNG) {
    this.config = config;
    this.randomFn = randomFn;
    this.inner =
      config.initialInner === undefined
        ? [...Array(config.xSize)].fill([]).map(() => [...Array(config.ySize)].fill(0))
        : config.initialInner;
    config.initialInner = this.inner;
  }

  public changeState(which: Point, state: number | ((old: number) => number)) {
    const res: number =
      typeof state === 'number' ? state : state(this.inner[which.x][which.y]);
    this.inner[which.x][which.y] = res < 0 ? 0 : res;
    return this.inner[which.x][which.y];
  }

  public get(which: Point) {
    return this.inner[which.x][which.y];
  }

  public randomizeGrid() {
    const noise = new SimplexNoise(this.randomFn);
    for (let i = 0; i < this.config.xSize; i++) {
      for (let j = 0; j < this.config.ySize; j++) {
        let noised = noise.noise2D(i / 10, j / 10);
        this.inner[i][j] = noised <= -0.3 ? 2 : noised <= 0.4 ? 4 : 6;
      }
    }
  }
}
