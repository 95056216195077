import { MainScene } from './main.scene';
import { mainToolbar, ToolbarItem } from '../shared/models/toolbar';
import { GameObjects } from 'phaser';

export type ToolbarDisplayItem = {
  id: string;
  texture: string;
  onActivate: (scene: MainScene) => void;
  onDeactivate: (scene: MainScene) => void;
};

export type ToolbarItemFull = ToolbarItem & ToolbarDisplayItem;

export type ToolbarConfig = {
  defaultItemId: string;
  xStart: number;
  yStart: number;
  buttonSeparatorSize: number;
};

export const mainToolbarDisplay: ToolbarDisplayItem[] = [
  {
    id: 'ecocup',
    texture: 'ecocup',
    onActivate: (scene: MainScene) => {
      scene.grid?.particles.setFrame('red');
    },
    onDeactivate: () => {},
  },
  {
    id: 'ecopinte',
    texture: 'ecopinte',
    onActivate: (scene: MainScene) => {
      scene.grid?.particles.setFrame('blue');
    },
    onDeactivate: () => {},
  },
];

export class Toolbar {
  private scene: MainScene;
  private config: ToolbarConfig;
  private items: Record<string, ToolbarItemFull>;
  private renderObj: Record<string, GameObjects.Image>;
  private activeItem?: string;

  constructor(scene: MainScene, config: ToolbarConfig) {
    this.scene = scene;
    this.config = config;
    this.items = {};
    this.renderObj = {};

    mainToolbar.forEach((item) => {
      const matchingDisplay = mainToolbarDisplay.find((s) => s.id == item.id);
      if (matchingDisplay === undefined) {
        return;
      }
      this.items[item.id] = {
        ...item,
        ...matchingDisplay,
      };
    });
  }

  public getActiveItem() {
    return this.activeItem;
  }

  public toggleItem(itemId: string) {
    // Disabling old item online if exists
    if (this.activeItem !== undefined) {
      const old = this.items[this.activeItem];
      old.onDeactivate(this.scene);
      this.renderObj[this.activeItem].setFrame(old.texture + '-off', false, false);
    }
    // Enabling the new one
    const newz = this.items[itemId];
    newz.onActivate(this.scene);
    this.renderObj[itemId].setFrame(newz.texture + '-on', false, false);
    // Save the current activeItem
    this.activeItem = itemId;
  }

  public onHoverEnter(itemId: string, texture: string) {
    this.renderObj[itemId].setFrame(texture + '-on', false, false);
  }

  public onHoverExit(itemId: string, texture: string) {
    if (this.activeItem != itemId) {
      this.renderObj[itemId].setFrame(texture + '-off', false, false);
    }
  }

  public onClick(itemId: string) {
    this.toggleItem(itemId);
  }

  public render() {
    let pxlCounter = 0;
    for (const [key, val] of Object.entries(this.items)) {
      this.renderObj[key] = this.scene.add
        .image(
          this.config.xStart + pxlCounter,
          this.config.yStart,
          'toolbar',
          val.texture + '-off',
        )
        .setDisplayOrigin(0, 0)
        .setInteractive({ useHandCursor: true });

      const newWidth = this.renderObj[key].width / 2;
      const newHeight = this.renderObj[key].height / 2;
      this.renderObj[key].setDisplaySize(newWidth, newHeight);

      this.renderObj[key].on(
        'pointerover',
        () => {
          this.onHoverEnter(key, val.texture);
        },
        this,
      );

      this.renderObj[key].on(
        'pointerout',
        () => {
          this.onHoverExit(key, val.texture);
        },
        this,
      );

      this.renderObj[key].on(
        'pointerdown',
        () => {
          this.onClick(key);
        },
        this,
      );

      pxlCounter += newWidth + this.config.buttonSeparatorSize;
    }

    this.toggleItem(this.config.defaultItemId);
  }
}
