import { Point } from './shared.game.grid';
import { memoize } from '../utils';

export type ToolbarItem = {
  id: string;
  affected: (point: Point) => WeightedPoint[];
  hitCost: number;
};

export type WeightedPoint = Point & {
  weight: number;
};

const buildPoints = (initial: Point, offset: number[][]) => {
  return offset.map((p) => {
    return {
      x: p[0] + initial.x,
      y: p[1] + initial.y,
      weight: p[2],
    };
  });
};

export const mainToolbar: ToolbarItem[] = [
  {
    id: 'ecocup',
    hitCost: 5,
    affected: memoize((point: Point) => {
      return buildPoints(point, [
        // x, y, weight
        [0, 0, 1],
        [1, 0, 1],
        [-1, 0, 1],
        [0, 1, 1],
        [0, -1, 1],
      ]);
    }),
  },
  {
    id: 'ecopinte',
    hitCost: 10,
    affected: memoize((point: Point) => {
      return buildPoints(point, [
        // x, y, weight
        [0, 0, 2],
        [1, 0, 2],
        [-1, 0, 2],
        [0, 1, 2],
        [0, -1, 2],
        [1, 1, 1],
        [1, -1, 1],
        [-1, 1, 1],
        [-1, -1, 1],
      ]);
    }),
  },
];
