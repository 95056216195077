export const memoize = <T = unknown>(fn: (val: T) => unknown) => {
  const cache = new Map();
  const cached = function (this: unknown, val: T) {
    return cache.has(val)
      ? cache.get(val)
      : cache.set(val, fn.call(this, val)) && cache.get(val);
  };
  cached.cache = cache;
  return cached;
};
