import { io, Socket } from 'socket.io-client';
import { MainScene } from './main.scene';
import { EventParams, ReservedOrUserListener } from '@socket.io/component-emitter';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { ExtendedError } from 'socket.io/dist/namespace';
import { WebsocketAuthAction, WebsocketAuthErrorData } from '../shared/models/websocket';
import { ariseIdLoginRoute } from '../shared/routes.csts';

export class Websocket {
  public readonly socket: Socket;
  public mainScene: MainScene;

  constructor(mainScene: MainScene) {
    this.mainScene = mainScene;
    this.socket = io();
    this.socket.once('connect', () => {
      console.log('WS: Successfully connected to server !');
    });
    this.socket.on('connect_error', (err: ExtendedError) => {
      if (err.message !== 'autherror') {
        console.log('WS: Unexpected error: ' + err);
        return;
      }
      const data: WebsocketAuthErrorData = err.data;
      console.log('WS: Socket authentication error: ' + err.message);
      switch (data.action) {
        case WebsocketAuthAction.LOGIN:
          window.location.href = ariseIdLoginRoute;
          return;
      }
    });
    this.socket.on('disconnect', (reason) => {
      console.log('WS: Lost connection: ' + reason);
    });
  }

  public emit(event: string, ...args: EventParams<DefaultEventsMap, string>) {
    return this.socket.emit(event, args);
  }

  public listen(
    event: string,
    callback: ReservedOrUserListener<DefaultEventsMap, DefaultEventsMap, string>,
  ) {
    return this.socket.on(event, callback);
  }
}
