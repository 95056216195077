import Image = Phaser.GameObjects.Image;
import { Scene } from 'phaser';
import { Grid } from './grid';
import Pointer = Phaser.Input.Pointer;
import { websocket } from './game';
import { BreakRequest, CellUpdate } from '../shared/models/break.events';
import { Point } from '../shared/models/shared.game.grid';

export class Cell {
  public readonly grid: Grid;
  public readonly scene: Scene;
  public readonly coord: Point;
  public readonly pixel: Point;
  public readonly absPixel: Point;
  private state: number;
  private renderObj?: Image;

  constructor(grid: Grid, x: number, y: number, state: number) {
    this.state = state;
    this.scene = grid.scene;
    this.grid = grid;
    this.coord = { x, y };
    this.pixel = this.grid.getPixelFromPoint(this.coord);
    this.absPixel = {
      x: this.pixel.x + this.grid.renderConfig.parentContainer.x,
      y: this.pixel.y + this.grid.renderConfig.parentContainer.y,
    };
  }

  private onHoverEnter(_pointer: Pointer) {
    this.grid.selector.setVisible(true);
    this.grid.selector.setPosition(this.absPixel.x, this.absPixel.y);
  }

  private onHoverExit(_pointer: Pointer) {
    this.grid.selector.setVisible(false);
  }

  private onClick(_pointer: Pointer) {
    const breakReq: BreakRequest = {
      which: this.coord,
      toolbarId: this.grid.scene.toolbar?.getActiveItem() || '',
    };
    websocket.emit('break-request', breakReq);
    this.scene.cameras.main.shake(100, 0.009);
  }

  onBreak(packet: CellUpdate) {
    this.grid.particles.explode(
      12,
      this.absPixel.x + this.grid.cellCenterOffset,
      this.absPixel.y + this.grid.cellCenterOffset,
    );
    this.changeState(packet.newState);
    return this;
  }

  changeState(state: number | ((old: number) => number)) {
    const res: number = typeof state === 'number' ? state : state(this.state);
    this.state = res < 0 ? 0 : res;
    this.renderObj?.setFrame(this.state);
    return res;
  }

  render() {
    this.renderObj = this.scene.add
      .image(this.pixel.x, this.pixel.y, 'cell-layers', this.state)
      .setDisplayOrigin(0, 0)
      .setDisplaySize(this.grid.renderConfig.cellSize, this.grid.renderConfig.cellSize);
    this.grid.renderConfig.parentContainer.add(this.renderObj);
    this.renderObj.setInteractive({ useHandCursor: true });
    this.renderObj.on('pointerover', this.onHoverEnter, this);
    this.renderObj.on('pointerout', this.onHoverExit, this);
    this.renderObj.on('pointerdown', this.onClick, this);
  }
}
