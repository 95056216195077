import * as Phaser from 'phaser';
import GameConfig = Phaser.Types.Core.GameConfig;
import { MainScene } from './main.scene';
import { Websocket } from './websocket';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin';
import AwaitLoaderPlugin from 'phaser3-rex-plugins/plugins/awaitloader-plugin';
import CustomProgressPlugin from 'phaser3-rex-plugins/plugins/customprogress-plugin';

const mainScene = new MainScene();

export const websocket = new Websocket(mainScene);

const config: GameConfig = {
  type: Phaser.WEBGL,
  plugins: {
    global: [
      {
        key: 'rexWebFontLoader',
        plugin: WebFontLoaderPlugin,
        start: true,
      },
      {
        key: 'rexAwaitLoader',
        plugin: AwaitLoaderPlugin,
        start: true,
      },
      {
        key: 'rexCustomProgressPlugin',
        plugin: CustomProgressPlugin,
        start: true,
      },
    ],
  },
  render: {
    mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
  },
  scale: {
    autoCenter: Phaser.Scale.CENTER_BOTH,
    mode: Phaser.Scale.FIT,
    parent: 'game',
  },
  scene: [mainScene],
};

export class Game extends Phaser.Game {
  constructor(config: GameConfig) {
    super(config);
  }
}

const game = new Game(config);
