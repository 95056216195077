import treasures_json_priv from '../treasures.shared.json' assert { type: 'json' };
import { Point } from '@shared/models/shared.game.grid';

export const treasuresItems: TreasuresItem[] = treasures_json_priv;

export const findTreasureJsonItem = function (tid: string): TreasuresItem | undefined {
  return treasuresItems.find((item) => item.tid === tid);
};

export type TreasureGridItem = {
  //Treasure texture id
  tid: string;
  //Treasure local id
  lid: string;

  // Treasure cell number
  cellsSize: number;

  cellsLeft: Point[];
  offset: Point;
  // right-hand clockwise
  angle?: number;
  flipX?: boolean;
  flipY?: boolean;
};

export enum TreasRarity {
  COMMON,
  UNCOMMON,
  RARE,
  EPIC,
  LEGENDARY,
}

export type TreasuresItem = {
  tid: string;
  name: string;
  rarity: TreasRarity;
  size: Point;
  shape: Point[];
};

export type TreasuresJson = TreasuresItem[];
