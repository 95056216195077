import { MainScene } from './main.scene';
import CustomProgress from 'phaser3-rex-plugins/plugins/customprogress';
import { GameObjects } from 'phaser';
import { Rectangle } from 'phaser3-rex-plugins/plugins/gameobjects/shape/shapes/geoms';
import CustomShapes from 'phaser3-rex-plugins/plugins/gameobjects/shape/customshapes/CustomShapes';

export type HealthBarConfig = {
  x: number;
  y: number;
  width: number;
  height: number;
  maxVal: number;
};

export class HealthBar {
  private scene: MainScene;
  private config: HealthBarConfig;
  private readonly fg: GameObjects.Image;
  private readonly mg: GameObjects.Image;
  private readonly bg: GameObjects.Image;
  private readonly bar: CustomProgress;

  public static getAttachedBar(scene: MainScene) {
    const bar = new CustomProgress(scene, {
      type: 'Graphics',
      create: [{ name: 'mask', type: 'rectangle' }],
    }).setDisplayOrigin(0, 0);
    scene.add.existing(bar);
    return bar;
  }

  constructor(scene: MainScene, attachedBar: CustomProgress, config: HealthBarConfig) {
    this.bar = attachedBar.setPosition(config.x, config.y);
    this.scene = scene;
    this.config = config;
    this.bg = new GameObjects.Image(scene, config.x, config.y, 'healthbar', 'hb-bg')
      .setDisplayOrigin(0, 0)
      .setDisplaySize(config.width, config.height);
    this.mg = new GameObjects.Image(scene, config.x, config.y, 'healthbar', 'hb-mg')
      .setDisplayOrigin(0, 0)
      .setDisplaySize(config.width, config.height);
    this.fg = new GameObjects.Image(scene, config.x, config.y, 'healthbar', 'hb-fg')
      .setDisplayOrigin(0, 0)
      .setDisplaySize(config.width, config.height);

    // Badly made type definition in phaser3-rex-plugins
    attachedBar.setUpdateShapesCallback(() => {
      const mask = this.bar.getShape('mask') as Rectangle;
      mask.setTopLeftPosition(0, 0);
      mask.setSize(this.config.width, this.config.height * (1 - this.bar.value));
      mask.fillStyle(0);
    });

    attachedBar.setEaseValueFunction('Quadratic');
    attachedBar.setEaseValueDuration(400);
    attachedBar.setValue(0);
  }

  public attachToScene() {
    this.scene.add.existing(this.bg);
    this.scene.add.existing(this.mg);
    this.scene.add.existing(this.fg);
    this.mg.setMask(this.bar.createGeometryMask().setInvertAlpha());
  }

  public setValue(val: number) {
    this.bar.easeValueTo(val, 0, this.config.maxVal);
  }
}
