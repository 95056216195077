import { GameObjects, Scene } from 'phaser';
import { Grid } from './grid';
import { Toolbar } from './toolbar';
import { GlowPipeline } from './pipelines/GlowPipeline';
import { InitialGameConfig } from '@shared/models/game.config';
import { websocket } from './game';
import { HealthBar } from './healthbar';
import WebGLRenderer = Phaser.Renderer.WebGL.WebGLRenderer;

export class MainScene extends Scene {
  private mainBackground?: GameObjects.Image;

  private gridContainer?: GameObjects.Container;
  private backgroundContainer?: GameObjects.Container;
  private treasuresContainer?: GameObjects.Container;
  private treasLeftText?: GameObjects.Text;
  public healthBar?: HealthBar;

  public grid?: Grid;
  public toolbar?: Toolbar;

  // Loading part
  private gameInitConfig?: InitialGameConfig;
  private loadingText?: GameObjects.Text;

  constructor() {
    super({
      key: 'MainScene',
    });
  }

  public setTreasLeft(num: number) {
    const plurial = num > 1 ? 's' : '';
    this.treasLeftText?.setText(`${num} tresor${plurial} restant${plurial}`);
  }

  preload() {
    (<WebGLRenderer>this.renderer).pipelines.addPostPipeline('GlowEffect', GlowPipeline);

    const screenCenterX = this.cameras.main.worldView.x + this.cameras.main.width / 2;
    const screenCenterY = this.cameras.main.worldView.y + this.cameras.main.height / 2;
    this.loadingText = this.add
      .text(screenCenterX, screenCenterY, 'Chargement ...', {
        fontSize: '5em',
      })
      .setOrigin(0.5);
    this.loadingText.setPostPipeline('GlowEffect');
    this.loadingText.setData(
      'glowTask',
      this.tweens.add({
        targets: this.loadingText.getPostPipeline('GlowEffect'),
        intensity: 0.03,
        ease: Phaser.Math.Easing.Sine,
        duration: Phaser.Math.Between(400, 700),
        repeat: -1,
        yoyo: true,
      }),
    );
    console.log(websocket.socket);

    // @ts-ignore (bypass unexisting rexWebFont on LoaderPlugin
    this.load.rexWebFont({
      custom: {
        families: ['Northead'],
        urls: ['assets/css/font.css'],
      },
    });

    this.load.on('webfontactive', (fileObj: Phaser.Loader.File, fontName: string) => {
      this.treasLeftText?.setFontFamily(fontName);
    });

    //Main background
    this.load.image('main-background', 'assets/img/main-background.jpg');

    // Healthbar
    this.load.atlas(
      'healthbar',
      'assets/img/healthbar/healthbar.png',
      'assets/img/healthbar/healthbar.json',
    );
    // Selector pointer
    this.load.image('cell-hover', 'assets/img/cell-hover.png');
    // Toolbar
    this.load.atlas(
      'toolbar',
      'assets/img/toolbar/toolbar.png',
      'assets/img/toolbar/toolbar.json',
    );
    // Grid background
    this.load.image('grid-background', 'assets/img/grid-background.jpg');
    // All cell layers, from bottom layer to top, first bottom layer is transparent
    this.load.spritesheet('cell-layers', 'assets/img/cell-layers.png', {
      frameWidth: 256,
      frameHeight: 256,
    });
    // Pretty particles
    this.load.atlas('flares', 'assets/particles/flares.png', 'assets/particles/flares.json');
    // All treasures (to avoid cheating by cherry pick only right ones)
    this.load.multiatlas(
      'treasures',
      'assets/img/treasures/treasures.json',
      'assets/img/treasures',
    );

    /*this.load.rexAwait((success) => {
      setTimeout(success, 3000);
    });*/
  }

  async websocketHandshake() {
    return new Promise<void>((resolve) => {
      websocket.listen('initial_res', (gameConfig: InitialGameConfig) => {
        this.gameInitConfig = gameConfig;
        this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, () => {
          this.loadingText?.destroy(true);
          resolve();
        });
        this.cameras.main.fadeOut(200, 0, 0, 0);
      });
      websocket.emit('initial_req');
    });
  }

  async create() {
    (<WebGLRenderer>this.renderer).pipelines.addPostPipeline('GlowEffect', GlowPipeline);
    await this.websocketHandshake();

    const screenCenterX = this.cameras.main.worldView.x + this.cameras.main.width / 2;
    const screenCenterY = this.cameras.main.worldView.y + this.cameras.main.height / 2;
    const xStartOffset = 40;
    const yStartOffset = 90;

    const attachedBar = HealthBar.getAttachedBar(this);

    this.mainBackground = this.add
      .image(screenCenterX, screenCenterY, 'main-background')
      .setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    if (this.gameInitConfig === undefined) {
      this.treasLeftText?.destroy(true);
      this.add
        .text(screenCenterX, screenCenterY, 'ERREUR ...', {
          fontSize: '5em',
        })
        .setOrigin(0.5);
      return;
    }

    this.backgroundContainer = this.add.container(xStartOffset, yStartOffset);
    this.treasuresContainer = this.add.container(xStartOffset, yStartOffset);
    this.treasuresContainer.setDataEnabled();
    this.gridContainer = this.add.container(xStartOffset, yStartOffset);
    this.grid = new Grid(
      {
        cellSize: Math.floor(650 / this.gameInitConfig.grid.xSize),
        parentContainer: this.gridContainer,
        bgContainer: this.backgroundContainer,
        treasuresContainer: this.treasuresContainer,
      },
      this.gameInitConfig.grid,
    );
    this.grid.render();

    this.treasLeftText = this.add.text(
      xStartOffset + 130,
      yStartOffset - 100,
      'treasLeftLbl',
      {
        fontSize: '75px',
        fontFamily: 'Northead',
        color: '#fff',
        stroke: '#f28e1c',
        strokeThickness: 15,
        shadow: {
          offsetX: 2,
          offsetY: 2,
          color: '#98590f',
          stroke: true,
          fill: true,
        },
      },
    );
    this.setTreasLeft(this.gameInitConfig.numTreas);

    this.healthBar = new HealthBar(this, attachedBar, {
      x: this.grid.absoluteEndX() + 25,
      y: yStartOffset + 25,
      width: 292,
      height: 450,
      maxVal: this.gameInitConfig.maxHits,
    });
    this.healthBar.attachToScene();

    this.toolbar = new Toolbar(this, {
      defaultItemId: 'ecocup',
      xStart: xStartOffset + 50,
      yStart: this.grid.absoluteEndY() + 2,
      buttonSeparatorSize: 20,
    });

    this.toolbar.render();

    this.cameras.main.fadeIn(200, 0, 0, 0);
  }
}
