[
  {
    "tid": "kro-common",
    "name": "La Kro' Normale",
    "size": { "x": 2, "y": 2 },
    "rarity": 0,
    "shape": [
      { "x": 0, "y": 0 },
      { "x": 0, "y": 1 },
      { "x": 1, "y": 0 },
      { "x": 1, "y": 1 }
    ]
  },
  {
    "tid": "kro-epic",
    "name": "La Kro' Gastronomique",
    "size": { "x": 2, "y": 2 },
    "rarity": 3,
    "shape": [
      { "x": 0, "y": 0 },
      { "x": 0, "y": 1 },
      { "x": 1, "y": 0 },
      { "x": 1, "y": 1 }
    ]
  },
  {
    "tid": "kro-legendary",
    "name": "Le Kro'kro",
    "size": { "x": 2, "y": 2 },
    "rarity": 4,
    "shape": [
      { "x": 0, "y": 0 },
      { "x": 0, "y": 1 },
      { "x": 1, "y": 0 },
      { "x": 1, "y": 1 }
    ]
  },
  {
    "tid": "kro-rare",
    "name": "La Kro' Gustative",
    "size": { "x": 2, "y": 2 },
    "rarity": 2,
    "shape": [
      { "x": 0, "y": 0 },
      { "x": 0, "y": 1 },
      { "x": 1, "y": 0 },
      { "x": 1, "y": 1 }
    ]
  },
  {
    "tid": "kro-uncommon",
    "name": "La Bonne Kro'",
    "size": { "x": 2, "y": 2 },
    "rarity": 1,
    "shape": [
      { "x": 0, "y": 0 },
      { "x": 0, "y": 1 },
      { "x": 1, "y": 0 },
      { "x": 1, "y": 1 }
    ]
  }
]
